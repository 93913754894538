
import { defineStore } from "pinia";
import { io } from 'socket.io-client';
import { ref } from 'vue'


import { useChatStore } from '@/store/chats';

// import { inject } from 'vue';
// import axios from "axios";


export const useSocketStore = defineStore('sockets', () => {

  const socket = ref(null)
  const auth = ref(null)
  const chatStore = ref(useChatStore())

  
  function connect() {
    if (socket.value) {
      socket.value.disconnect();
    }
    if(process.env.NODE_ENV === 'development') {
      socket.value = io('ws://10.0.1.231:8006', { transports: ['websocket'] });
    } else {
      socket.value = io('https://apiexpertspk.dev.ao-nk.ru', { transports: ['websocket', 'polling'],  path: '/ws/socket.io', secure: true});
    }

    reconnect()
    listen()
  }

  function reconnect() {
    socket.value.io.on("reconnect", () => {
        console.log('Reconnect')
        login()
    });
  }

  function disconnect() {
    if (socket.value) {
      socket.value.disconnect();
    }
  }





  function listen(){
    socket.value.on("newMessage", (data) => {
      chatStore.value.newMessage(data);
    });

    socket.value.on("editMessage", (data) => {
      chatStore.value.editMessage(data);
    });

    socket.value.on("removeMessage", (data) => {
      chatStore.value.removeMessage(data);
    });

    socket.value.on("newChat", (data) => {
      chatStore.value.addChat(data);
    });

    socket.value.on("removeChat", (data) => {
      chatStore.value.removeChat(data);
  });

    socket.value.on('getChats', (data) => {
      chatStore.value.setChats(data);
    });

    socket.value.on('newMember', (data) => {
      chatStore.value.addMember(data);
    });

    socket.value.on('removeMember', (data) => {
      chatStore.value.removeMember(data);
    });

    socket.value.on('loadMessages', (data) => {
      chatStore.value.loadMessages(data);
    });

    
  }




  function login(){
    if(auth.value.check()){
        socket.value.emit('auth', {
            token: auth.value.token()
        });
    }
  }

  function emit(action, data){
    socket.value.emit(action, data);
  }

  
  return { socket, connect, disconnect, auth, login, emit, reconnect, chatStore }
})



// Socket IO почему выберает transport polling

