<template>
    <v-main>
        <slot />
    </v-main>
</template>


<script>

export default {
    name: "DefaultLayout"
}

</script>