import { defineStore } from "pinia";
import Cookies from 'js-cookie'
import axios from "axios";


export const useMainStore = defineStore('main', {
  state: () => ({
    council: null
  }),
  actions: {
    setCouncil(object){
      this.council = object;
      Cookies.set('setCouncil', object.id, { expires: 365 })
    },
    async getCouncils(){
      try {
        let councils = await axios.get('/councils/my');

        return councils.data;
      } catch (error) {
        console.log(error)
        return [];
      }
    },
    async getProjects(page, search, council, directions){
      try {
        page = page ?? 1;
        search = search ?? '';
        council = council ?? this.council.id;
        directions = directions ?? []

        if((page == 1) && (council == this.council.id)) this.council.projects = [];
        // if((this.council.projects.length == this.council.countProjects) && (council == this.council.id)) return false
        let projects = await axios.post(`/council/${council}/projects?page=${page}&perPage=10&search=${search}`, {
          directions: directions
        })

        if(council == this.council.id) this.council.projects = [...projects.data.projects, ...this.council.projects];
        if(council == this.council.id) this.council.countProjects = projects.data.count;
        return projects.data;
      } catch (error) {
        console.log(error)
        return [];
      }
    },
    addProject(object){
      this.council.projects.push(object);
    },
    setProject(object){
      this.council.projects[this.council.projects.findIndex(p => p.id === object.id)] = object;
    },
    async getMembers(){
      if(this.council.members){
        return this.council.members
      }else{
        try {
          let members = (await axios.get(`/council/${this.council.id}/users`)).data;
          this.council.members = members;
          return members
        } catch (error) {
          console.log(error)
          return [];
        }
      }
    }
  },
  getters: {
    getCouncilId(){
      return Cookies.get('setCouncil');
    }
  }
})