let config
if (process.env.NODE_ENV == 'development') {
  config={
    url: 'http://10.0.1.205:3000/oauth',

    params: {
      // ид клиента
      clientId: '51c90d39-7eaa-4dae-97a0-933abe8f422b',
      // требуемые разрешения (АО НК может отдать меньше разрешений, но не больше чем было указно в настроках клиента)
      scopes: [
        'phone',
        'name',
        'surname',
        'patronymic',
        'email',
        'avatar',
        'birthday',
        'rfCitizen',
        'inn',
        'gender',
        'postAddress',
        'regionCode',
        'snils',
        'city'
      ],
    }
  }
} else {
  config={
    url: 'https://id.ao-nk.ru/oauth',

    params: {
      // ид клиента
      clientId: '369e3b62-9896-48fa-84ea-a105abebecf7',
      // требуемые разрешения (АО НК может отдать меньше разрешений, но не больше чем было указно в настроках клиента)
      scopes: [
        'phone',
        'name',
        'surname',
        'patronymic',
        'email',
        'avatar',
        'birthday',
        'rfCitizen',
        'inn',
        'gender',
        'postAddress',
        'regionCode',
        'snils',
        'city'],
    }
  }
}

module.exports = config
