import { defineStore } from "pinia";
import { ref } from 'vue'

import { useSocketStore } from '@/store/sockets';


export const useChatStore = defineStore('chats', () => {
    const chats = ref([]);
    const load = ref(false);
    const socket = ref(useSocketStore());


    const connect = () => {
        socket.value.connect()
    }

    const auth = async (auth) => {
        socket.value.auth = auth;
        socket.value.login()
    }

    const disconnect = () => {
        socket.value.disconnect();
    }




    const setChats = async (data) => {
        chats.value = data;
        load.value = true;
    }

    const getChats = async () => {
        socket.value.emit('getChats');
    }



    const createChat = async (data) => {
        socket.value.emit('createChat', data);
    }


    const addUser = async (data) => {
        socket.value.emit('addUser', data);
    }

    const removeUser = async (data) => {
        socket.value.emit('removeUser', data);
    }



    const addChat = async (data) => {
        chats.value.push(data);
    }

    const removeChat = async (data) => {
        let chatIndex = chats.value.findIndex(chat => chat.chatId == data.chatId);
        chats.value.splice(chatIndex, 1);
    }





    const addMember = async (data) => {
        let chatId = data.chatId;
        let chatIndex = chats.value.findIndex(chat => chat.chatId == chatId);

        chats.value[chatIndex].chat.members.push(data);
    }

    const removeMember = async (data) => {
        let chatIndex = chats.value.findIndex(chat => chat.chatId == data.chatId);
        let memberIndex = chats.value[chatIndex].chat.members.findIndex(member => member.id == data.userId);

        chats.value[chatIndex].chat.members.splice(memberIndex, 1);
    }




    const sendMessage = async (data) => {
        socket.value.emit('sendMessage', data);
    }

    const newMessage = async (data) => {
        let chatId = data.chatId;
        let chatIndex = chats.value.findIndex(chat => chat.chatId === chatId);

        chats.value[chatIndex].chat.messages.push(data);
        chats.value[chatIndex].chat.lastMessage = data;

        if(data.userId == chats.value[chatIndex].userId){
            chats.value[chatIndex].lastReadMessageId = data.id;
        }
    }




    const sendEditMessage = async (data) => {
        socket.value.emit('editMessage', data);
    }

    const editMessage = async (data) => {
        let chatId = data.chatId;
        let chatIndex = chats.value.findIndex(chat => chat.chatId === chatId);

        let messageIndex = chats.value[chatIndex].chat.messages.findIndex(message => message.id == data.id)
        chats.value[chatIndex].chat.messages[messageIndex] = data;

        
    }




    const sendRemoveMessage = async (data) => {
        socket.value.emit('removeMessage', data);
    }

    const removeMessage = async (data) => {
        let chatId = data.chatId;
        let chatIndex = chats.value.findIndex(chat => chat.chatId === chatId);

        let messageIndex = chats.value[chatIndex].chat.messages.findIndex(message => message.id == data.id)
        chats.value[chatIndex].chat.messages.splice(messageIndex, 1);
    }





    const getMessages = async (data) => {
        socket.value.emit('getMessages', data);
    }

    const loadMessages = async (data) => {
        let chatId = data.chatId;
        let chatIndex = chats.value.findIndex(chat => chat.chatId === chatId);

        chats.value[chatIndex].chat.messages = data.messages.concat(chats.value[chatIndex].chat.messages);
    }



    const readMessage = async (data) => {
        let chatId = data.chatId;
        let chatIndex = chats.value.findIndex(chat => chat.chatId === chatId);

        chats.value[chatIndex].lastReadMessageId = data.messageId;

        socket.value.emit('readMessage', data);
    }

    return {
        chats,
        socket,
        load,
        setChats,
        getChats,
        addChat,
        removeChat,
        addMember,
        createChat,
        removeMember,
        connect,
        auth,
        disconnect,
        sendMessage,
        newMessage,
        getMessages,
        loadMessages,
        sendEditMessage,
        editMessage,
        sendRemoveMessage,
        removeMessage,
        addUser,
        removeUser,
        readMessage
    }
})