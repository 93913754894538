<template>
  <v-app full-height class="w-100" :theme="theme.theme">
      <component :is="layout">
          <router-view/>
      </component>
    </v-app>
</template>
<script>

import { useChatStore } from '@/store/chats';
import { useThemeStore } from '@/store/theme';


export default {
  name: 'App',
  setup() {
    const chat = useChatStore();
    const theme = useThemeStore();

    return { chat , theme };
  },
  data: () => ({
    //
  }),
  computed: {
    layout(){
        return this.$route.meta.layout || "default-layout" 
    },
    loaded() {
      return (
        this.$auth.check()
      );
    }
  },
  watch: {
    loaded(val){
      if (val == true) {
       this.chat.auth(this.$auth)

       setTimeout(() => {
        this.chat.getChats();
       }, 3000)
      }
    }
  },
  mounted() {
    this.chat.connect()
    this.theme.initTheme();
  },
  beforeUnmount() {
    this.chat.disconnect();
  }
}


// Vue js Sockets Я хочу сделать чат на основе сокета как это сделать во vue js в pinia есть методы: sendMessage, newMessage, getChat, getChats, createChat
</script>

<style lang="scss">

</style>
