// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import '@/styles/generic/_reset.scss'


// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
    theme: {
      defaultTheme: 'light',
      themes: {
        dark: {
            colors: {
                btnColor: '#305dc0',
                surfaceOpacity: '#ffffff1a',
            },
            opacity: '0.1'
        },
        light: {
            colors: {
              btnColor: '#27a9ea',
              btnColorRed: '#ff5b5b',
              background: '#e6e6e6',
              surface: '#fffeff',
              surfaceOpacity: '#000000',
            },
            opacity: '0.1'
          }
      }
    },
    options: {
        customProperties: true
      }
  })

// Я испольхую vue с vuetify. Как мне сделать и подключить файл с глобальными переменными scss
