<template>
    <div v-if="user">
        <v-navigation-drawer
            v-model="drawer"
            :rail="rail"
            permanent
            @mouseover="rail = false" 
            @mouseleave="rail = true" 
            
        >
          <div class="d-flex flex-column justify-space-between h-100">
            <div>
              <v-list-item
                  :prepend-avatar="user.profile.avatar ? $storagePath + user.profile.avatarImg.fileName : ''"
                  :title="`${user.profile.name} ${user.profile.surname}`"
                  :subtitle="user.email"
                  :to="`/cp/user/${user.id}`"
                  nav
                  height="63px">
                  <template v-slot:prepend v-if="!user.profile.avatar">
                    <div class="avatar d-flex justify-center align-center text-subtitle-2 font-weight-bold">
                      {{ user.profile.name.substr(0,1) }}
                    </div>
                  </template>
                

              </v-list-item>
            
              <v-divider></v-divider>

              <v-list density="compact" nav>
                  <!-- <v-list-item v-if="councils.length" prepend-icon="mdi-home" title="Главная" value="home" to="/cp"></v-list-item> -->
                  <v-list-item v-if="councils.length" prepend-icon="mdi-file-document" title="Проекты" value="projects" to="/cp/projects"></v-list-item>
                  
                    <v-list-item  title="Сообщения" value="messages" to="/cp/messages">
                      <template v-slot:prepend>
                        <v-badge color="btnColor" v-if="chats.filter(item => item.chat.messages.filter(message => message.id > (item.lastReadMessageId ?? 0)).length != 0).length != 0" :content="chats.filter(item => item.chat.messages.filter(message => message.id > (item.lastReadMessageId ?? 0)).length != 0).length">
                          <v-icon>mdi-message</v-icon>
                        </v-badge>
                        <v-icon v-else>mdi-message</v-icon>
                      </template>
                    </v-list-item>
                  <v-list-item prepend-icon="mdi-account-group" title="Друзья" value="friends" to="/cp/friends"></v-list-item>
                  <!-- <v-list-item prepend-icon="mdi-account" title="Мой профиль" value="profile" to="/cp/profile"></v-list-item> -->
                  <v-list-item v-if="!councils.length" prepend-icon="mdi-account-group-outline" title="СПК" value="council" to="/cp/council"></v-list-item>
                  <v-list-item v-if="user.isAdmin" prepend-icon="mdi-cog" title="Панель администратора" value="admin-panel" to="/cp/admin-panel"></v-list-item>
              </v-list>
            </div>

              <div class="d-flex justify-space-between" v-if="councils.length != 0">
                <v-menu location="top">
                    <template v-slot:activator="{ props }">
                      <v-list class="px-1">
                        <v-list-item
                          v-bind="props"
                          class="rounded-lg"
                          style="padding: 6px;"
                        >
                          <template v-slot:prepend>
                            <img class="council-img mr-3" :src="$storagePath + councilStore?.council?.image.fileName">
                          </template>
                          <v-list-item-title class="text-caption font-weight-bold">{{ councilStore?.council?.title }}</v-list-item-title>
                          <template v-slot:append>
                              <v-icon size="small">mdi-menu-down</v-icon>
                          </template>
                        </v-list-item>
                      </v-list>
                    </template>

                    <div>
                      <v-list class="mt-3 pa-2 rounded-lg" style="border: 2px solid rgba(255, 255, 255, 0.05)">
                        <v-list-item
                          v-for="(item, key) in councils"
                          :key="key"
                          active-color="primary"
                          v-show="item.council"
                          class="rounded-lg mb-1 pa-0">

                          <template v-slot:prepend v-if="item.council">
                            <v-list-item :value="key" class="mr-3 rounded-lg" @click="pageToCouncil(`/cp/council/${item.council.id}`, item.council.id)" style="min-width: 200px;">
                              <template v-slot:prepend>
                                <img class="council-img mr-3" :src="$storagePath + item.council?.image.fileName">
                              </template>
                              <v-list-item-title class="text-caption font-weight-bold">{{ item.council.title }}</v-list-item-title>
                            </v-list-item>
                          </template>
                          <template v-slot:append v-if="item.council">
                            <v-btn size="x-small" icon="mdi-check" color="btnColor" variant="outlined" v-if="item.council.id == councilId" @click="selectCouncil(item.council.id)"></v-btn>
                          </template>
                        </v-list-item>
                        <v-list-item
                          value="add"
                          active-color="primary"
                          to="/cp/council"
                          class="rounded-lg mt-2">
                          

                          <template v-slot:prepend>
                            <div style="width: 35px" class="mr-3 d-flex ">
                              <v-icon>mdi-plus</v-icon>
                            </div>
                          </template>
                          <v-list-item-title class="text-caption font-weight-bold">Добавить СПК</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    </div>
                </v-menu>
              </div>
            
          </div>
      </v-navigation-drawer>
      
      <!-- <v-app-bar app>
        <div class="w-100 h-100 d-flex justify-end">
          <div class="councils h-100 mr-10" v-if="councils.length">
            <v-menu :location="location">
              <template v-slot:activator="{ props }">
                <v-list class="px-2">
                  <v-list-item
                    v-bind="props"
                    class="rounded-lg"
                  >
                    <template v-slot:prepend>
                      <img class="council-img mr-3" :src="$storagePath + councilStore?.council?.image.fileName">
                    </template>
                    <v-list-item-title class="text-caption font-weight-bold">{{ councilStore?.council?.title }}</v-list-item-title>
                    <template v-slot:append>
                      <v-icon
                        size="small"
                      >mdi-menu-down</v-icon>
                    </template>
                    
                  </v-list-item>
                </v-list>
              </template>

              <div>
                <v-list class="mt-3 pa-2">
                  <v-list-item
                    v-for="(item, key) in councils"
                    :key="key"
                    active-color="primary"
                    class="rounded-lg mb-1 pa-0">

                    <template v-slot:prepend>
                      <v-list-item :value="key" class="mr-3 rounded-lg" :to="`/cp/council/${item.council.id}`">
                        <template v-slot:prepend>
                          <img class="council-img mr-3" :src="$storagePath + item.council?.image.fileName">
                        </template>
                        <v-list-item-title class="text-caption font-weight-bold">{{ item.council.title }}</v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:append>
                      <v-btn size="x-small" icon="mdi-check" color="btnColor" @click="selectCouncil(item.council.id)"></v-btn>
                    </template>
                  </v-list-item>
                  <v-list-item
                    value="add"
                    active-color="primary"
                    to="/cp/council"
                    class="rounded-lg mt-2">
                    

                    <template v-slot:prepend>
                      <div style="width: 35px" class="mr-3 d-flex ">
                        <v-icon>mdi-plus</v-icon>
                      </div>
                    </template>
                    <v-list-item-title class="text-caption font-weight-bold">Добавить СПК</v-list-item-title>
                  </v-list-item>
              </v-list>
              </div>
            </v-menu>
          </div>
        </div>
      </v-app-bar> -->
      
      <v-main theme="dark">
        <slot />
      </v-main>

      <Alert />
      
    </div>
  </template>
  

<script>
import { defineComponent } from 'vue'
import { useMainStore } from '@/store/main';
import Alert from '@/components/Common/Alert.vue';


import { useChatStore } from '@/store/chats';


export default defineComponent({
  components: { Alert },
  name: "CpLayout",
  setup() {
      const chat = useChatStore();

      return { chat };
  },
  data () {
    return {
      drawer: true,
      rail: true,
      councilStore: useMainStore(),
      councils: []
    }
  },
  async mounted(){
    if(!this.$auth.check()) this.$router.push({ name: 'login' })
    if(this.user){
      
      this.councils = this.user.councils.filter(object => (object.role == 2 || object.role == 3) && (object.target == 'council'));
      console.log(this.councils)
      let councilId = this.councilStore.getCouncilId;

      if(!this.councils.length) return 0;
      if(!councilId) this.councilStore.setCouncil(this.councils[0].council)

      console.log(councilId)

      if(councilId){
        let council = (this.councils.find(object => object.targetId == councilId))?.council;
        if(council) this.councilStore.setCouncil(council)
      } 
    }


    
    

    // this.socketInit();
  },
  methods: {
    selectCouncil(id){
      let council = (this.councils.find(object => object.targetId == id))?.council;
      if(council) this.councilStore.setCouncil(council)
    },
    pageToCouncil(url, id){
      this.selectCouncil(id);
      this.$router.push(url);
    }
  },
  
  computed: {
      user(){
        return this.$auth.user();
      },
      councilId(){
        return this.councilStore.council.id
      },
      chats(){
        return this.chat.chats;
      }
  },
  watch: {
    user(){
      if(!this.$auth.check()) this.$router.push({ name: 'login' })
      if(this.user){
        this.councils = this.user.councils.filter(object => (object.role == 2 || object.role == 3) && (object.target == 'council'));
        let councilId = this.councilStore.getCouncilId;

        if(!this.councils.length) return 0;
        if(!councilId) this.councilStore.setCouncil(this.councils[0].council)

        console.log(councilId)

        if(councilId){
          let council = (this.councils.find(object => object.targetId == councilId))?.council;
          if(council) this.councilStore.setCouncil(council)
        } 
      }
    }
  }
})

// socket.io-client vue использвание

</script>

<style lang="scss" scoped>

.cp-layout{
    .slot{
        width: calc(1100px - 300px);
    }

    .user-card:hover{
        background: rgba(var(--v-theme-surfaceOpacity), 0.05);
        cursor: pointer;
    }
}


.council-img{
  width: 35px;
  height: 35px;
  border-radius: 100px;
  object-fit: cover;
  
}

.avatar{
  width: 35px;
  height: 35px;
  border-radius: 100px;
  background: rgba(var(--v-theme-surfaceOpacity), 0.06);;
  margin-left: 3px;
  margin-right: 15px;
}

</style>



