import { defineStore } from "pinia";
import { ref } from 'vue'
import Cookies from 'js-cookie'


export const useThemeStore = defineStore('theme', () => {
    const theme = ref('light');

    const initTheme = () => {
        let themeCookie = Cookies.get('theme');

        if(themeCookie) theme.value = themeCookie;
        if(!themeCookie) setTheme('light');
    }

    const setTheme = (value) => {
        Cookies.set('theme', value, { expires: 365 })
        theme.value = value;
    }

    return { theme, initTheme, setTheme}
});