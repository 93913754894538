<template>
  <div class="alert">
    <v-card 
        v-for="(alert, alertKey) in alert.alerts" 
        :key="alertKey" 
        class="alert-block d-flex rounded-lg py-3 px-5 ma-2 mr-3" width="330" :class="alert.type">

        <div class="border-icon pa-3 d-flex align-center justify-center">
            <v-icon :color="types[alert.type].color">{{ types[alert.type].icon }}</v-icon>
        </div>
        <div class="ml-5 mr-2">
            <div class="title text-subtitle-1 font-weight-bold">{{ types[alert.type].text }}</div>
            <div class="text text-subtitle-2 font-weight-bold">{{ alert.message }}</div>
        </div>
    </v-card>
  </div>
</template>

<script>

export default {
    name: 'AlertVue',
    data(){
        return {
            types: {
                success: {
                    text: 'Успешно',
                    icon: 'mdi-check',
                    color: '#03ff5774'
                },
                error: {
                    text: 'Ошибка',
                    icon:'mdi-close',
                    color: '#ff43438f'
                },
                warning: {
                    text: 'Предупреждение',
                    icon:'mdi-shield-alert',
                    color: '#eaff03a1'
                }
            }
        }
    },
    computed: {
        alert() {
            return this.$alert;
        }
    }
}
</script>

<style lang="scss">
.alert{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999999;

    .success{
        border: 2px solid #03ff5774;


        .border-icon{
            border: 2px solid #03ff5774;
        }
    }



    .error{
        border: 2px solid #ff43438f;


        .border-icon{
            border: 2px solid #ff43438f;
        }
    }


    .warning{
        border: 2px solid #eaff03a1;


        .border-icon{
            border: 2px solid #eaff03a1;
        }
    }



    .border-icon{
        border-radius: 100px;
        height: 52px;
        width: 52px;
    }


    .text{
        opacity: 0.7;
        line-height: 1rem;
    }
}
</style>