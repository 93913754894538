import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../pages/Index.vue')
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('../pages/Auth/SignIn.vue')
  },
  {
    path: '/auth/register',
    name: 'register',
    component: () => import('../pages/Auth/SignUp.vue')
  },
  {
    path: '/auth/restore/:id/:token',
    name: 'restore',
    component: () => import('../pages/Auth/ResetPassword.vue')
  },
  {
    path: '/auth/restore-request',
    name: 'restore-request',
    component: () => import('../pages/Auth/ResetPasswordRequest.vue')
  },
  {
    path: '/auth/confirm/:id/:token',
    name: 'confirm',
    component: () => import('../pages/Auth/Confirm.vue')
  },
  {
    path: '/cp',
    name: 'cp',
    component: () => import('../pages/cp/Index.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/cp/profile',
    name: 'cp-profile',
    component: () => import('../pages/cp/Profile.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/cp/council',
    name: 'cp-council',
    component: () => import('../pages/cp/Council.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/cp/council/:id',
    name: 'cp-council-id',
    component: () => import('../pages/cp/Councils/Council.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/cp/admin-panel',
    name: 'cp-admin-panel',
    component: () => import('../pages/cp/AdminPanel.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/cp/messages',
    name: 'cp-messages',
    component: () => import('../pages/cp/Messages.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/cp/projects',
    name: 'cp-projects',
    component: () => import('../pages/cp/Projects.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/cp/project/:id',
    name: 'cp-project-id',
    component: () => import('../pages/cp/Projects/Project.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/cp/friends',
    name: 'cp-friends',
    component: () => import('../pages/cp/Friends.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/cp/user/:id',
    name: 'cp-user',
    component: () => import('../pages/cp/User/User.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
